<template>
  <div class="page">


    <!--居中对齐-->
    <div class="notice-wrapper" v-show="ui.noticeBarStatus">
      <div class="center">
        <el-button
            size="small"
            :plain="false"
            type="primary"
            style="opacity: 0.5;display: block;width: 100%;margin-top: 5px;white-space: nowrap;"
            @click="close()">
          <i class="fa fa-warning i-gap"></i>
          请点击 " 对话框 " 右上角 <i class="fa fa-close"></i> 按钮，以
          <span style="color:red">" 正常关闭 "</span> 弹窗！
        </el-button>
      </div>
    </div>

    <!--表单滚动区域-->
    <div class="form-scroll">

      <el-form ref="form" :model="form" :label-width="ui.labelWidth">

        <template v-for="(item,index) in caption.tableFields"
                  v-if="!item.isFormHidden && item.formFormat!='password'">

          <el-form-item :label="item.comment" :required="item.required" :prop="item.name">

            <template v-if="item.linkTableName!=''">

              <el-button
                  @click=mySelect(item)
                  :disabled="!form[item.name] || form[item.name]=='0'"
                  :plain="true"
                  type="primary"
                  size="mini">
                <i :class="'fa '+(item.linkMultiRows?'fa-tag':'fa-tag')"></i> 我的选择
              </el-button>

            </template>

            <template
                v-else-if="item.formFormat=='number' || item.formFormat=='datanumber' || item.formFormat=='money'">
              <el-input-number
                  v-model="form[item.name]"
                  size="small"
                  autocomplete="off"
                  :disabled="true">
              </el-input-number>
            </template>

            <template v-else-if="item.formFormat=='datetime' || item.formFormat=='date' || item.formFormat=='time'">
              <el-date-picker
                  v-model="form[item.name]"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  :disabled="true">
              </el-date-picker>
            </template>

            <template
                v-else-if="item.formFormat=='checkbox' || item.formFormat=='boolean' || item.formFormat=='check_mark'">
              <el-switch
                  :active-value="1 || true"
                  v-model="form[item.name]"
                  :disabled="true">
              </el-switch>
            </template>

            <template v-else-if="item.formFormat=='filepath'">

              <el-button
                  @click=openFile(form[item.name])
                  :disabled="!form[item.name]"
                  :plain="true"
                  type="primary"
                  size="mini">
                <i class="fa fa-file-image-o"></i> 文件打开
              </el-button>

            </template>

            <template v-else-if="item.formFormat=='textarea' || item.formFormat=='editor'">
              <el-input
                  type="textarea"
                  v-model="form[item.name]"
                  autocomplete="off"
                  :readonly="true">
              </el-input>
            </template>

            <template v-else>
              <el-input
                  v-model="form[item.name]"
                  autocomplete="off"
                  :readonly="true">
              </el-input>
            </template>

            <template v-if="item.linkTableName==''">
              <div class="fa-icon">
                <i v-if="icon[item.formFormat]!=null"
                   :class="'fa-icon fa '+icon[item.formFormat]"></i>
                <i v-else class="fa-icon fa fa-file-text-o"></i>
              </div>
            </template>

          </el-form-item>

        </template>

      </el-form>
    </div>

  </div>
</template>

<script>

import {getDetail, getDetailModel, getEntity} from '@/api/public.js'
import config from '/vue.config.js'

export default {
  name: "showTableDetail",
  data() {
    return {

      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,

      // 界面元素状态
      ui: {
        noticeBarStatus: true,
        labelWidth: '120px',
      },

      // 输入参数
      caption: {
        curFieldName: '',
        tableName: '',
        tableAlias: '',
        tableTitle: '',
        keyIdName: '',
        id: '',
        title: '编辑',
      },

      // 表单数据
      form: {},

      // 后缀图标
      icon: {
        "text": "fa-file-text-o",
        "String": "fa-file-text-o",
        "money": "fa-rmb",
        "email": "fa-envelope-o",
        "password": "fa-key",
        "telephone": "fa-phone",
        "scan": "fa-camera-retro",
        "sex": "fa-user-o",
        "select": "fa-list",
        "remark": "fa-edit",
        "date": "",
        "datetime": "",
        "time": "",
        "updatedate": "",
        "createdate": "",
        "mycreate": "fa-user-o",
        "number": "",
        "datanumber": "",
        "filepath": "",
        "checkbox": "",
        "boolean": "",
        "check_mark": "",
        "radio": "",
        "textarea": "",
        "editor": "",
      },

    }
  },
  created() {

    // 初始化
    this.initParm();
    if (this.caption.tableName) {
      this.initCaption();
    }
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  methods: {
    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;

      // 参数到模型
      for (let key in e) {
        if (key == "tableName") {
          let tableName = e[key];
          let tableAlias = tableName.replace(/_/g, "");
          tableAlias = tableAlias.toLowerCase();
          this.caption.tableAlias = tableAlias;
          this.caption[key] = tableName;
        } else {
          this.caption[key] = e[key];
        }
      }
      this.setResize();
    },
    // 初始化数据格式
    initCaption() {
      // 数据格式
      getDetailModel(this.caption.tableName).then(res => {
        for (let key in res) this.caption[key] = res[key];
        this.initData();
      });
    },
    // 初始化数据
    initData() {
      // 数据
      if (this.caption["id"]) {
        getDetail(this.caption.tableName, this.caption.id).then(res => {
          //不可以呢？
          this.form = res;
          this.$forceUpdate();
        });
      } else {
        this.$forceUpdate();
      }
    },
    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.ui.labelWidth = '80px';
        } else {
          this.ui.labelWidth = '120px';
        }
      });
    },
    // 我的选择
    mySelect(item) {

      let linkTableName = item['linkTableName'];
      let value = this.form[item['name']];
      let linkUrl = "showTableMain?tableName=" + linkTableName + "&isMastePage=false&ids=" +
          value + "&inIds=true&method=mySelect&to=detail";

      this.$router.push(linkUrl);
    },
    // 关闭+后退
    close() {
      if (this.caption.method == 'mySelect') {
        this.goBack();
      } else {
        window.parent.closeDialog();
      }
    },
    openFile(url){
      let linkUrl = "showImageDetail?url="+url+"&to=detail&tableTitle="+this.caption.tableTitle;
      this.$router.push(linkUrl);
    },

  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

// 滚动条样式
#app .form-scroll::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);

  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--通知性质栏--*/
.notice-wrapper {

  margin: 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

}

/*--表单滚动栏--*/
.form-scroll {

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;

  height: calc(100% - 150px);

  overflow-y: auto;

  background-image: url(../../static/images/bg_1.png);
  background-repeat: no-repeat;
  background-position: right bottom;

  .fa-icon {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #606266;
  }

  .el-form-item {

    margin-bottom: 5px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    /*--2行--*/
    float: left;
    width: 50%;

    .el-form-item__label {
      white-space: nowrap;
      word-break: break-all;
      overflow: auto;
    }

  }

  textarea {
    position: absolute;
    z-index: 100;
    top: -40px;
    height: 40px;
  }

  textarea:focus {
    height: 105px;
  }

  .el-input__inner {
    padding-right: 35px;
  }

}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }

}


</style>